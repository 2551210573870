
.topic-container {
    width: 100%;
    margin: 0 auto;
    padding: 5px;
    box-sizing: border-box;
  }  
  
  .topics {  
    padding: 10px;
    margin: 30px auto;     
    box-sizing: border-box;  
  }
  
  .topic-button {
    position: relative;  /* This makes it the reference point for the absolutely positioned lock icon*/
    padding-bottom: 15px;
    font-weight: 700;
    font-size: clamp(17px, 4vw, 23px) !important;
    text-align: left;
    width: 100%;
    cursor: pointer;   
    color: #062e5f;
    background-color: white;
    border: none;
    display: flex;
  }

  .topics hr {
    border: none;
    height: 1px;
    background-color: #F0F0F0;
    margin: 0 auto; 
    width: calc(100% - 20px);
    transform: translateY(10px);
  }

  .topic-button:hover, .topic-option:hover{
    transform: translateY(-4px);
  }

  .topic-options {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .topic-option {
    display: flex;
    align-items: center;
    color: #062e5f;
    padding: 10px;
    border-radius: 10px;
    background-color: #F5F5F5;
    cursor: pointer;
    border: none;
    width: 100%;
    font-size: clamp(15px, 2.8vw, 18px) !important;
  }
  
  .topic-option img {
    margin-right: 10px;
  }

  .arrow-right {
    margin-left: auto;
  }
   
@media (min-width: 600px) {
  .topics{
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 800px) {
  .topics{
    padding-left: 70px;
    padding-right: 70px;
  }
}
@media (min-width: 1100px) {
  .topic-container {
    width: 70%;
  }
  .topics{
    max-width: 1000px;
  }
}
 