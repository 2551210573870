  .top-icons-container{
    display: flex;
    align-items: center;
  }

  .book-icon {    
    padding: 0px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  .flag-icon {
    padding: 0px; 
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .practice-page {
    /*to make dialog height dynamic*/
    display: flex;
    flex-direction: column;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    margin: 0 auto;
    padding: 5px;
    box-sizing: border-box;  
  } 
  
  .practice-body{
    /*to make dialog height dynamic*/
    display: flex;    
    flex-direction: column;
    flex: 1;
    overflow: hidden; 
    width: 100%;
    padding: 10px;
    margin: 0px auto;     
    box-sizing: border-box;
  }

  .scenario-description {  
    display: flex;
    width: 100%;
    border: 2px solid #FFC145	;
    box-shadow: 0 2px 0 rgba(255,193,69, 0.8);
    padding: 5px 10px 5px 10px; /*top right bottom left*/
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    max-height: 20%; 
    overflow-y: auto; /* Enables scrolling for overflow text */
  }

  .collapse-button{
    width : 30px; 
    height: 20px;
    margin-left: auto;
    padding: 0px;
    border: 2px solid #FFC145	;
    background-color: rgba(255,193,69, 0.3);;
    border-radius: 5px;
  }  

  .practice-container {
    /*to make dialog height dynamic*/
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden; 
  }
  .conversation-dialog-container{
    margin: 5px auto;
    flex: 1;
    overflow-y: auto;    
    width: 100%;
    min-height: 300px;
    border-radius: 5px;
    padding: 5px 0 5px  0;
    border-radius: 5px;
  }
  .conversation-dialog {
    display: flex;
    flex-direction: column;
    align-items: flex-start;    
  }
    
  .application-user-container{
    margin-right: auto;  
    max-width: 90%;
    
  }
  .application-answer { 
    border-radius: 0px 10px 10px 10px;
    background-color: #062e5f; /* #E1EEE6; */
    padding: 10px 15px 10px 15px;
    font-size: clamp(17px, 3.5vw, 20px) !important;
  }  
  .application-answer hr {
    border: none;
    height: 0.3px;
    background-color:  #66D5C1;
  }
  
  .user-answers-container{
    margin-left: auto;   
    max-width: 90%;
  }
  .user-answers {     
    background-color:   #F5F5F5;    
    border-radius: 10px 0px 10px 10px; 
    margin-bottom: 10px;
    padding: 10px 15px 10px 15px;
    font-size: clamp(17px, 3.5vw, 20px) !important;
  }
  .user-answer {    /* words 'Option' */
    display: flex;
    padding: 2px;  
    font-size: clamp(15px, 3.5vw, 20px) !important;  
  }
  .user-answers hr {
    border: none;
    height: 0.5px;
    background-color:  #e3e3e3;
  }

  .speaker-button-practice,
  .slowdown-button-practice,
  .translation-button-practice , 
  .transcription-button-practice   {
    width: 30px;
    height: 30px;
    padding: 0px; 
    border: none;
    margin-left: 20px; 
    background-color: transparent; 
    cursor: pointer;
  }

  .speaker-button-practice:hover,
  .slowdown-button-practice:hover,
  .translation-button-practice:hover,
  .transcription-button-practice:hover {
    transform: translateY(-2px);
  }

  .microphone-practice {
    text-align: center;
    position: relative; 
    margin-bottom: 20px;
  }
  .microphone-button-practice {
    cursor: pointer;
    background: none;
    border: none;
  }
  .recording-animation-practice {  
    background: none;
    cursor: pointer;
    border: none;
    animation: pulseAnimation 2s infinite; 
  }

  .popup h2 {
    margin-top: 0;
    color: #333;
  }
  
  .popup ul {
    list-style: none;
    padding: 0;
  }
  
  .popup li {
    padding: 10px;
    margin-bottom: 10px;
    background-color: #e3e3e3;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .popup li:hover {
    background-color: #ccc;
  }
  
  .situation-button { 
    font-weight: 700;
    font-size: clamp(17px, 4vw, 23px) !important;    
    cursor: pointer;   
    color: #062e5f;
    background-color: white;
    border: none;
    display: flex;
  }
  .situation-change {
    display: flex;
    background-color: white;
    color: #062e5f;
    cursor: pointer;
    border: none;
    font-size: clamp(16px, 2.5vw, 16px) !important;
  }
  .situation-button:hover,
  .situation-change:hover {
    transform: translateY(-2px);
  }
  .situation { 
    font-size: clamp(17px, 3.5vw, 20px) !important;    
    color: #062e5f;
  }

  @keyframes fadeInOut {
    0% { opacity: 0; }
    10% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
  }
  @media (min-width: 600px) {  
    .practice-body     {       
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  @media (min-width: 800px) {
    .practice-body {       
      padding-left: 70px;
      padding-right: 70px;
    }
  }
  @media (min-width: 1100px) {  
    .practice-page {
      max-width: 80%;
    }  
    .practice-body {       
      max-width: 1000px;
    }   
  }