.grammar-page {
    width: 100%;
    margin: 0 auto;
    padding: 5px;
    box-sizing: border-box;
    font-size: clamp(14px, 3vw, 18px) !important;
    color: #062E5F;
}
.grammar-page ul, .grammar-page ol {
    padding-left: 10px; /* Adjust this value as needed */
    list-style-position: inside; /* Place bullet inside the padding area */
}
.grammar-content, .grammar-content p{
  margin: auto;
  font-size: clamp(16px, 3vw, 18px) !important;
}

.table-content {    
    border-collapse: collapse;
    border: 1px solid #000;
    margin: auto;
  }

  .sub-title-1{
    font-weight: 700;
    padding-top: 10px;
  }

  .sub-title-2{
    font-weight: 700;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .sub-title-3{
    font-weight: 500;
    padding-top: 10px;
  }
    
  .table-content th {
    padding: 8px;
    border: 1px solid #000;    
    text-align: left;
    max-width: 200px;
  }

  .table-content td {
    border: 1px solid #000;    
    text-align: left;
    padding: 5px;
    max-width: 200px;
    line-height: 1.8; /* Adjust as necessary */
    vertical-align: top;
  }

  .max-width-100 {
    max-width: 30px;
    white-space: normal; /* This allows text to wrap within the cell */
    word-wrap: break-word; /* This helps in breaking long words */
  }

  .link {
    color: #062e5f; 
    cursor: pointer;
    text-decoration: underline;
  }

  .grammar-page table.table-content tbody tr:first-child td:first-child div {
    padding-bottom: 10px !important; /* force style on first <td> because it does not work for some reason */
  }

  .ipa-list {   
    margin: -10px auto;
    width: 80%; 
    /* display: grid;
    grid-template-columns: repeat(4, 1fr); */
  }
  .ipa-item{
    margin: 10px auto;
    width: 100px;
    border-radius: 10px;
    background-color: #ffffff; /* #d1eee6;   */
    padding: 7px 20px 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-size: clamp(14px, 6vw, 25px) !important;
  }

  .listen-button{ /*for ipa sound*/
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border: none;
    background-color: transparent; 
    cursor: pointer;
    transform: translateY(3px);
  }

  .grammar-topic-options {
    padding-bottom: 10px;
    flex-direction: column;
  }

  .my-vocabularies-list{
    margin: 30px auto;
    box-sizing: border-box;  
    color: #062E5F;    
    display: flex;
    flex-wrap: wrap; 
    gap: 15px;  
    font-size: clamp(13px, 3vw, 16px);
  }

  @media (min-width: 600px) {
    .table-content th {
      max-width: 250px;
    }  
    .table-content td {
      max-width: 250px;
    }
    .ipa-list {   
      grid-template-columns: repeat(6, 1fr);
    }
    .grammar-content{
      width: 90%;
    }
  }
  @media (min-width: 800px) {
    .table-content th {
      max-width: 300px;
    }  
    .table-content td {
      max-width: 300px;
    }    
  }
  @media (min-width: 1100px) {
    .grammar-page {
      max-width: 70%;
    }
    .grammar-content{
      width: 80%;
    }
    
  } 