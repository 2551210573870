
  .completion-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  
  .completion-details {
    list-style-type: none;
    padding: 0;
    text-align: Left;
  }
  
  .completion-details li {
    margin-bottom: 5px;
    font-size: clamp(17px, 3.5vw, 20px) !important;
  }

  .completion-details p {
    font-size: clamp(16px, 3vw, 19px) !important;
  }
  
  .report-button {
    padding: 12px 20px;
    border: none;
    border-radius: 10px;
    color: white;
    cursor: pointer; 
    transition: all 0.3s; 
    display: flex;
    align-items: center;
    background-color: #062E5F;
    min-width: 300px;
    max-width: 500px;
    margin-top: 10px;
    font-size: clamp(16px, 3vw, 18px) !important;
  }

  .report-button:hover {
    transform: translateY(-2px);
  }
     
  .print-container{
    display: flex;
    justify-content: end;
    margin-top: 30px;
  }

  /* Show the print section when printing */
  @media print {
    body * {
      visibility: hidden;
    }

    .print-section, .print-section * {        
      visibility: visible; 
      margin-bottom: auto;
    } 
  }

  

  /********** REPORTS **********/
  .result-box {
    border-radius: 10px; 
    overflow: hidden; /* Ensures elements do not spill outside the border-radius */
    margin: 15px 10px 0 -30px;
    border: 2px solid #062e5f;
    color: #062E5F;
  }
  
  .line-1,
  .line-2 {
    display: flex;
    align-items: center;
  }
  .line-1 {
    border-bottom: 1px solid #062e5f;
  }

  .result-box .label {
      padding: 10px;    
      font-weight: 630;
      color: #062e5f;
      font-size: clamp(15px, 3vw, 18px) !important;
  } 

  .result-box .content { 
    flex-grow: 1;   
    word-break: break-word; 
    font-size: clamp(17px, 3vw, 18px) !important;   
  }
