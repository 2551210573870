/*
COLOR CODE	
aqua green	  #66D5C1	 (logo  R102 G213 B193;  C52 M0 Y9 K16; RGBA 255,193,69)
      --LIGHT AQUA GREEN #d1eee6
yellow orange #FFC145 (logo RGB(255, 193, 69) CMYK: 0.0%, 24.3%, 72.9%, 0.0%)
      -- MEDIUM YELLOW #FFEDCB rgb(255,237,203)
      --LIGHT YELLOW FFF1DF
      --LIGHTER YELLOW #FFF4DF  rgb(255,244,223)
dark blue     #062E5F (logo R6 G49 B95)
background: var(--PURPLE, #8D7FE0); -- LIGHT PURPLE #E9E6F9
background: var(--PINK, #E07F9C);  --LIGHT PINK #F9E6EC
background: var(--CYAN, #53CFEA);  --LIGHT CYAN #DBF5FB
background: var(--GREEN, #00C667);
background: var(--RED, #F40000);
background: var(--GREY, #B6B6B6);
off white	    #F0F0F0

dark green    #3eca7e (RGB 62 202 126)
light aqua    #C1EEE6
lighter aqua  #D1EEE6   #E1EEE6
pink	        #D5668A (RGB 213,102,138)	
dark grey     #c4c4c4
grey	        #ccc		

darker off white   #e3e3e3
red           #ff5f5f   #ff4c4c
*/

html {      /* titles */
  font-family: 'Arial', sans-serif; 
  font-size: clamp(15px, 3vw, 25px);
}
  
.top-row {
  display: flex;
  justify-content: space-between; /* This will push the back button to the left and the h1 to the center */
  align-items: center;
  padding-bottom: 0px;
  padding-top: 0px;
}

.back-button{
  background: none;
  color: #ffffff; 
  border: none;
  cursor: pointer;  
}

.back-button-popup{
  background: none;
  border: none;
  cursor: pointer;  
  width: 35px;
  height: 35px;
}

.next-button{  
  background-color: #062E5F;
  color: #ffffff; 
  border: none;
  border-radius: 7px;
  font-size: clamp(15px, 3vw, 18px);
  padding: 2px 10px 8px 10px;
  cursor: pointer;  
  margin-left: auto;
}
.next-button:hover {
  transform: translateY(-2px);
}

.submit-button{  
  margin-top: 30px;
  background-color: #062E5F;
  color: #ffffff; 
  border: none;
  border-radius: 7px;
  font-size: clamp(15px, 3vw, 20px);
  padding: 10px;
  cursor: pointer;  
}

h1, h2, h3, h4 { 
  color: #062E5F; 
  flex-grow: 1; /* This will make the h1 grow and use up the available space */
  text-align: center; /* This will center the text within the h1 element */
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: clamp(18px, 4vw, 28px) !important;
}
h1 {font-size: clamp(20px, 5vw, 27px) !important; }
h2 {font-size: clamp(18px, 4.5vw, 25px) !important; }
h3 {font-size: clamp(16px, 4vw, 22px) !important; }
h4 {font-size: clamp(14px, 3.5vw, 20px) !important; }

.icons-container{  
  display: flex;
  justify-content: space-around;  
  margin: 50px auto;
  max-width: 500px;
}

.icon {
  cursor: pointer;
}

.icon img{
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #062E5F;
  cursor: pointer; 
  text-decoration: underline;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p {
  margin: 0px;
  margin-left: 10px;
  color: #062E5F;
  font-size: clamp(15px, 3vw, 18px) !important;
}

.inactive{
  opacity: 1;
  cursor: not-allowed;
  position: relative;  /* This makes it the reference point for the absolutely positioned lock icon*/
}

.lock-icon {
  position: absolute;
  top: 50%; 
  margin-right: 10px;
  color: #ffffff;
  animation: shake 0.5s infinite;
}  
@keyframes shake {
  0% { transform: translateY(-50%) rotate(0); }
  25% { transform: translateY(-50%) rotate(5deg); }
  75% { transform: translateY(-50%) rotate(-5deg); }
  100% { transform: translateY(-50%) rotate(0); }
}
